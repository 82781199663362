import "./TierCard.css";
import {Infinity, Paypal, Check2} from "react-bootstrap-icons";
import {AccountTier, Api, ApiAuthentication} from "../../RequestsProvider/RequestProvider";
import React, {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";

interface Props {
    accountTier: AccountTier
    apiKeyInformation : ApiAuthentication
}

export const TierCard = (props: Props) => {

    const renderInfinity = () => {
        return (<Infinity className={""} size={20}/>)
    }
    const request = useContext(Api)
    const {upgradeTier} = request!


    return (
        <div className={`m-4 m-lg-2 pricing-box text-center acc-${props.accountTier.tierName}-pricing`}>
            <div className="row mb-4">
                <small
                    className={`col-12 mt-3 fw-bold acc-${props.accountTier.tierName}-header`}>{props.accountTier.tierName.toUpperCase()}</small>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <p className="price"><sup>€</sup>{props.accountTier.price}<sub>/mo</sub></p>
                </div>
            </div>
            <ul className="features-list px-4">
                <li>
                    <strong>{props.accountTier.maxPersons ? props.accountTier.maxPersons : renderInfinity()}</strong> Personen
                    für Geburtstagsnachrichten
                </li>
                <li>
                    <strong>{props.accountTier.transcriptionLimit ? props.accountTier.transcriptionLimit : renderInfinity()}{props.accountTier.transcriptionLimit ?
                        <sub>sec.</sub> : null}</strong> Transkriptionen<sub>/monat</sub>
                </li>
                <li><strong><Check2/></strong> Transkribieren verstecken</li>
                <li><strong><Check2/></strong> Transkribieren in Gruppen</li>
                <li><strong><Check2/></strong> Transkribieren übersetzen</li>
                <li><strong><Check2/></strong> Gelöschte Nachrichten sehen</li>
                <li><strong><Check2/></strong> ChatGPT Integration</li>
            </ul>
            <div className="row">
                <div className={`col-12 ${props.accountTier.tierID === 3 ? "mb-5" : "mb-4"}`}>
                    {props.apiKeyInformation? props.apiKeyInformation.tierID >= props.accountTier.tierID ?
                        <button disabled type="button" className="btn px-4 p-2">Current<br/></button>
                        :
                        <button type="button" className="btn btn-dark px-4 p-2"
                                onClick={() => upgradeTier(props.accountTier.tierID).then(resp=>!resp.message.link.includes('paypal')? toast("coming soon..."): window.location.href = resp.message.link)}>Upgrade<br/><small
                            className={"text-uppercase"}>mit PayPal <Paypal color={"#ffb42b"}/></small></button> : null
                    }
                </div>
            </div>
        </div>
    );
};
