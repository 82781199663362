import React, {useState} from "react";
import './SuccessView.css';
import RandomSVGPicture from "../../Components/RandomSVGPicture/RandomSVGPicture";
import {useSearchParams} from "react-router-dom";
import Confetti from "react-confetti";
import {useWindowSize} from "usehooks-ts";
export const SuccessView = () => {
    const [searchParams] = useSearchParams()
    const { width, height } = useWindowSize()
    const [showConfetti, setShowConfetti] = useState(true)

    const subscriptionId = searchParams.get('subscription_id');
    const baToken = searchParams.get('ba_token');
    const token = searchParams.get('token');


    return (
        <>
            {showConfetti && (
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                    numberOfPieces={500}
                    onConfettiComplete={() => setShowConfetti(false)} // Konfetti-Animation automatisch beenden
                />
            )}
            <div className="login" style={{position: "fixed"}}>
                <h1>
                    <RandomSVGPicture width={100} height={100} rotate={true}/><br/>
                </h1>
                <h1>Danke für dein Vertrauen :)</h1>
                <p className={"text-center"}>Dein Account wird aufgestuft, sobald die Transaktion abgeschlossen ist.</p>
                <h1>🎉</h1>
            </div>
        </>
    );
};
